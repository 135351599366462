@font-face {
    font-family: 'Minion Pro';
    src: url('https://db.onlinewebfonts.com/t/fe9c236a1c142788cb16ec5238c7833e.eot'); /* IE9*/
    src: url('https://db.onlinewebfonts.com/t/fe9c236a1c142788cb16ec5238c7833e.eot?#iefix')
            format('embedded-opentype'),
        /* IE6-IE8 */
            url('https://db.onlinewebfonts.com/t/fe9c236a1c142788cb16ec5238c7833e.woff2')
            format('woff2'),
        /* chrome firefox */
            url('https://db.onlinewebfonts.com/t/fe9c236a1c142788cb16ec5238c7833e.woff')
            format('woff'),
        /* chrome firefox */
            url('https://db.onlinewebfonts.com/t/fe9c236a1c142788cb16ec5238c7833e.ttf')
            format('truetype'),
        /* chrome firefox opera Safari, Android, iOS 4.2+*/
            url('https://db.onlinewebfonts.com/t/fe9c236a1c142788cb16ec5238c7833e.svg#Minion Pro')
            format('svg'); /* iOS 4.1- */
}

html {
    font-size: 62.5%; /* 62.5% of 16px = 10px */
}

body {
    margin: 0;
    font-family: 'Lato', sans-serif; // Also it's set in Material Theme!
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
}

* {
    box-sizing: inherit;
}

/* Fix for google maps hints, otherwise pac-container competes with material-ui styling and gets the property "display: none" */
.pac-container {
    z-index: 99999;
}
