.flag-select {
    transform: scale(1.2);
    left: 18px;
    margin-top: 2px;
}

.phone-input > div {
    input[type='tel'] {
        padding-left: 56px;
    }
}
